/* CSS */

.mobile_regis:active {
  box-shadow: 0 0.3em 1em -0.5em #14a73e98;
}

.ReactSwipeButton.mobile_regis {
  width: 77vw;
  height: 16vw;
  box-shadow: 0px 7px 7px -4px rgb(0 0 0);
}

.ReactSwipeButton {
  background-color: #0055fe;
  color: #fff;
  border-radius: 8px;
  text-align: center;
  margin: auto;
  float: left;
  width: 85%;
  height: 50px;
  position: absolute;
  bottom: 36.5vw;
  z-index: 1;
}

.ReactSwipeButton,
.ReactSwipeButton * {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                    not supported by any browser */
}

.ReactSwipeButton {
  float: unset;
  position: relative;
}

.rsbContainer {
  /* float: unset; */
  width: 100%;
  height: 100%;
  border-radius: 8px;
  overflow: hidden;
}

.rsbContainerUnlocked {
  width: 50% !important;
  margin-left: 25%;
  transition: 1.5s;
  cursor: default;
}

.rsbContainerUnlocked .rsbcSlider {
  cursor: default;
  pointer-events: none;
}

.rsbContainerUnlocked .rsbcSliderArrow {
  transition: 0.5s;
  margin-right: -3vw;
}

.rsbContainerUnlocked .rsbcSliderCircle {
  transition: 0.5s;
  margin-right: -3vw;
}

.rsbcSlider {
  /* float: left; */
  width: 100%;
  position: relative;
  height: 50px;
  top: 0;
  left: 13vw;
  margin-left: -100%;
  border-radius: 8px;
  z-index: 1;
  cursor: pointer;
}

.rsbcSliderText {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  line-height: 50px;
  text-align: center;
  letter-spacing: 2px;
  color: #fff;
  font-size: 13px;
}

#game-svg {
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
}

.rsbcSliderCircle svg {
  top: 2.75vw;
  left: 5px;
  width: 11vw !important;
  height: auto;
  animation-name: sds2;
  animation-duration: 750ms;
  position: relative;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
}
@keyframes sds2 {
  0% {
    transform: translateX(0.6rem);
  }

  50% {
    transform: translateX(-0.3rem);
  }

  100% {
    transform: translateX(0.6rem);
  }
}

.jugar-div .svg-inline--fa {
  animation-name: sts2;
  animation-duration: 0.4s;
  margin-left: 0.3vw;
}

.rsbcSliderCircle {
  position: absolute;
  text-align: center;
  color: #fff;
  background: #12459e;
  right: -2.15vw;
  top: 1vw;
  height: 14vw;
  width: 14vw;
  border-radius: 8px;
  z-index: 999999999;
}

.rsbcText {
  position: absolute;
  left: 9vw;
  color: #fff;
  font-size: 6.5vw;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 16vw;
  right: 0;
  text-transform: uppercase;
  top: 0.4vw;
}

/* Shine */

.shine {
  -webkit-mask-image: linear-gradient(
    -75deg,
    rgba(0, 0, 0, 0.85) 30%,
    #000 50%,
    rgba(0, 0, 0, 0.85) 70%
  );
  -webkit-mask-size: 200%;
  animation: shine 2s linear infinite;
}

@keyframes shine {
  from {
    -webkit-mask-position: 150%;
  }
  to {
    -webkit-mask-position: -50%;
  }
}
