body {
  background-color: #252a30;
  font-family: "Roboto";
}

html {
  font-size: 16px;
}

html,
body {
  overflow-x: hidden !important;
}

.spinner-border {
  color: #79c000;
  margin: auto;
}

::-webkit-scrollbar {
  width: 0;
}

::-webkit-scrollbar-button {
  width: 0;
}

.apps img {
  max-height: 60px;
}

/* stroke */
.top-groups-container {
  display: flex;
  align-items: center;
  padding: 0 2vw;
}

.header-group-right {
  display: flex;
  align-items: center;
  padding-top: 5px;
  /* right: 30px; */
}

.navbar-nav {
  /* padding-top: 0; */
  padding-left: 20px;
}

.stroke .navbar-nav a,
.stroke .navbar-nav a:after,
.stroke .navbar-nav a:before {
  transition: all 0.5s;
}

.stroke .navbar-nav a {
  position: relative;
}

.stroke .navbar-nav a:after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 0%;
  content: ".";
  color: transparent;
  background: #fff;
  height: 2px;
  border-radius: 5px;
}

.stroke .navbar-nav a:hover:after {
  width: 100%;
}

/* End Stroke */

.header_nav {
  /* justify-content: space-between;
  display: flex; */
  width: 100%;
  background-color: #242a30;
}

.header-group-right .btn {
  width: 120px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  border-radius: 6px;
  padding: 5px 15px;
  font-size: 1.15rem;
}

.navbar-dark .navbar-nav a {
  color: #fff;
  padding: 0.5rem;
  text-decoration: none;
}

.navbar-dark .navbar-nav a:hover {
  color: #fff;
}

nav {
  position: fixed !important;
  width: 100%;
  z-index: 6;
  height: 66px;
}

.bm-item-list img {
  max-width: 55px;
  margin-right: 5px;
}

/* DropDown */

.dropdown-item:active {
  color: #000;
  background-color: #fff !important;
}

.btn-check:active + .btn-primary:focus,
.btn-check:checked + .btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show > .btn-primary.dropdown-toggle:focus,
.btn-check:focus + .btn-primary,
.btn-primary:focus {
  box-shadow: unset;
}

.dropdown button {
  background: 0 0;
  border: unset;
  color: #000;
  /* font-size: 1vw; */
}

.dropdown .svg-inline--fa {
  height: 1.5vw;
  position: relative;
  top: 0.2vw;
}

.dropdown-toggle::after {
  display: none;
}

.dropdown button:hover {
  background: 0 0;
  color: #fff;
  border: unset;
}

.dropdown-menu[data-bs-popper] {
  margin-top: 0.4vw;
  left: -5vw;
}

.dropdown-menu {
  width: 15rem;
  background-color: #212529db;
  text-align: center;
  margin: auto;
}

.dropdown-item {
  margin: auto;
  color: #fff;
  border-bottom: #ffffff73 0.5px solid;
  padding: 0.55rem 0rem;
  text-transform: capitalize;
  width: 80%;
}

.dropdown a:nth-child(1) {
  padding-top: 5px;
}

.dropdown a:nth-last-child(1) {
  border: unset;
  padding-bottom: 5px;
}

.dropdown-item:hover {
  font-weight: 600;
}

.dropdown .dropdown-menu {
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.9s;
  max-height: 0;
  display: block;
  overflow: hidden;
  opacity: 0;
}

.dropdown.show .dropdown-menu {
  max-height: 23rem;
  opacity: 1;
}

/* End DropDown */

.acceder-button {
  border: unset;
  background-color: #79c000;
  color: #fff;
  box-shadow: 0px 2px 7px -4px rgb(0 0 0);
}

.acceder-button:hover {
  background-color: #79c000ca;
}

.btn:active {
  background-color: #0263b5aa !important;
}

.btn .btn-primary:hover {
  background-color: unset;
}

.central-regis,
.jugar-regis,
.bottom-game-regis {
  border-radius: 4px;
  padding: 0.4vw 1.3vw;
  margin: 0;
}

.bottom-game-regis {
  background: #c546d3;
}

.registrate-button:active {
  background-color: #0263b5aa !important;
}
.disclamer {
  font-size: 0.7vw;
}
.regis-tyc {
  display: flex;
  position: relative;
  bottom: 10vw;
  left: -24.4vw;
  gap: 0.35rem;
  height: 5vw;
  z-index: 1;
  flex-direction: column;
  align-items: center;
}

.central-regis {
  background: linear-gradient(180deg, #0587f5, #0057a0);
  line-height: 1.2;
  font-weight: 500;
  font-size: 1.6vw;
  padding: 12.5px 10px 12.5px;
  text-transform: uppercase;
  transition: all 300ms ease-in-out;

  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.tyc {
  cursor: pointer;
}

.tyc:hover {
  color: #79c000;
}

.jugar-div {
  position: relative;
  /* bottom: -59%; */
}

.jugar-regis {
  background: #c546d3;
  border: unset;
  width: 12vw;
  float: right;
  font-size: 1.2vw;
  transition: all 300ms ease-in-out;
}

.jugar-regis:hover {
  background: #c546d3aa;
  transition: all 300ms ease-in-out;
}

.central-regis svg {
  margin-left: 0.2vw;
}

.central-regis:hover svg {
  animation: sideToSide2 0.8s infinite;
}

@keyframes sideToSide2 {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(50%);
  }

  100% {
    transform: translateX(0);
  }
}

.login_btn {
  margin: auto;
  text-align: center;
  background-color: #79c000;
  border: #c0d49d;
  width: 65%;
  margin-bottom: 5vw;
  padding: 1em;
  border-radius: 20px;
}

.modal-content {
  border-radius: 10px;
}

#des-reg {
  position: absolute;
  width: 360px;
  max-width: 20vw;
  display: flex;
  flex-direction: column;
  top: 28vw;
  left: 16.25vw;
  text-align: center;
  z-index: 2;
  color: #fff;
}

.terms-tyc {
  background-color: rgb(255, 255, 255);
  color: #000;
  overflow-x: hidden;
  max-width: 450px;
}

.modal-body {
  overflow-x: hidden;
}

.terms-tyc .modal-body table {
  overflow-x: auto !important;
}

.terms-tyc .btn-close {
  position: absolute;
  right: 15px;
  top: 15px;
}

.terms-tyc .modal-title {
  margin: auto;
}

.terms-tyc .modal-title img {
  width: 75px;
}

.terms-tyc .modal-header,
.terms-tyc .modal-footer {
  border: unset;
}

.tyc-iframe {
  font-family: "Roboto", sans-serif;
  margin: auto;
  width: 95%;
  min-height: 400px !important;
  display: flex;
}

.terms-tyc ::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.terms-tyc ol {
  font-size: 0.75rem;
  counter-reset: my-counter;
  list-style-type: none;
  padding-left: 0;
}

.terms-tyc ol > li {
  counter-increment: my-counter;
  padding-left: 25px;
  position: relative;
}

.terms-tyc ol > li::before {
  content: counter(my-counter) ". ";
  color: #000;
  position: absolute;
  left: 0;
}

.modal-header {
  align-items: center;
}

.modal-header h3 {
  width: 100%;
  font-size: 1.35rem;
  text-align: center;
  margin: 0;
  padding: 0;
}

.modal-header .btn-close {
  padding: 0;
  margin: 0;
}

.table > :not(caption) > * > * {
  margin-left: 1rem;
}

.terms-tyc .modal-body {
  padding: 0.25rem 1rem;
}

.terms-tyc .accept-btn {
  background-color: #79c000;
  border: #79c000;
  border-radius: 6px;
  width: 100%;
  font-size: 1.15rem;
  font-weight: 700;
}

#modal_app {
  text-align: center;
}

#carousel-section,
.inicio {
  min-height: 90vh;
  padding-top: 4rem;
}

/* Swiper */

.banner-img {
  max-width: 100%;
  width: 100%;
  height: auto;
}

.swiper-slide img {
  width: 100%;
  height: auto;
  max-height: 890px;
}

.carousel-indicators [data-bs-target] {
  width: 8px;
  height: 8px;
  border-top: 15px;
  border-bottom: 15px;
}

.carousel-indicators .active {
  background-color: #79c000 !important;
}

.carousel-indicators button {
  border-radius: 150px !important;
}

.swiper-pagination-bullet-active {
  background-color: #79c000 !important;
}

.swiper-pagination-bullet {
  width: 14px;
  height: 14px;
}

/* GAMES */

.sport-games-slider {
  width: 75%;
  margin: auto;
  text-align: center;
}

#desktop_games {
  text-align: center;
}

#desktop_games .game-title,
#mobile_games .game-title {
  display: block;
  color: #fff;
  font-size: 1.75rem;
  font-weight: 600;
}

#desktop_games h2 {
  color: #fff;
  font-size: 1.4vw;
  color: #e8e5e5;
  font-weight: 700;
  /* margin-bottom: 1vw; */
  letter-spacing: 1px;
  /* margin-top: 2.5vw; */
}

#desktop_games h1:nth-child(1) {
  margin-top: 3vw;
}

.top_games_conatiner {
  display: inline-flex;
  grid-template-columns: auto auto 0;
  grid-gap: 3rem;
  padding: 1rem 0 4rem;
}

.top_games_conatiner .topGame {
  margin: auto;
  width: 35vw;
}

.topGameImage,
.game_logo {
  transition: 0.3s;
}

.top_games_conatiner .jugar-regis:hover svg {
  animation: sideToSide2 0.8s infinite;
}

.top_games_conatiner .card {
  background-color: #26363f;
  border-radius: 40px;
  height: 25vw;
  padding: 1rem;
  position: relative;
  overflow-x: hidden;
  border-radius: 16px;
}

img.topGameImage {
  position: absolute;
  width: 35vw;
  height: auto;
  top: 0;
  left: 0;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  -webkit-box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.78);
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.78);
}

img.game_logo {
  margin: auto;
  height: auto;
  width: 50%;
  position: relative;
  top: 4rem;
  z-index: 1;
  cursor: pointer;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.15, 1.15);
  }

  100% {
    transform: scale(1, 1);
  }
}

img.game_logo:hover {
  /* transform: scale(1.15); */
  animation: zoom-in-zoom-out 1s ease-out infinite;
}

.game_info {
  bottom: 1vw;
  /* position: absolute; */
  text-align: left;
  border-left: 4px #fff solid;
  border-radius: 1px;
  color: #fff;
  line-height: 0.25;
  padding-left: 1vw;
  margin-left: 1vw;
}

.topGameTitle {
  color: #fff;
  font-size: 1.5vw;
  font-weight: 700;
  letter-spacing: -1px;
}

/* Botttom Games */
.bottom_conatiner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: 80%;
  margin: auto;
  cursor: pointer;
}

.bottom_conatiner h3 {
  /* margin-top: 6px; */
  color: #fff;
  font-size: 1.3vw;
}

.bottom-box .game img {
  /* width: 26rem; */
  width: 100%;
  height: auto;
  border-radius: 15px;
}

.bottom-box .logo img {
  height: auto;
  width: auto;
  max-width: 80%;
}

.bottom_conatiner .bottom-card {
  position: relative;
  min-width: 22vw;
  height: 28vw;
  box-shadow:
    inset 5px 5px 5px rgb(0 0 0 / 20%),
    inset -5px -5px 15px rgb(255 255 255 / 10%),
    5px 5px 15px rgb(0 0 0 / 30%),
    -5px -5px 15px rgb(255 255 255 / 10%);
  border-radius: 15px;
  margin: 25px 0;
  transition: 0.5s;
}

.bottom_conatiner .bottom-card .game {
  transition: 0.5s;
}

.bottom_conatiner .bottom-card .bottom-box {
  position: absolute;
  height: 25vw;
  /* top: 0; */
  left: 15px;
  right: 15px;
  bottom: 2.5vw;
  border-radius: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.bottom_conatiner .bottom-card:hover .game {
  transform: scale(0.9) translateY(-5vw) !important;
}

.game-card svg {
  animation: sideToSide2 0.8s infinite;
}

.bottom_conatiner .bottom-card:hover .logo {
  transform: scale(1.05) translateY(-90px);
  transition: all 300ms ease-in-out;
}

.bottom_conatiner .bottom-card .bottom-box .bottom-content {
  padding: 10px;
  text-align: center;
}

.bottom_conatiner .bottom-card .bottom-box .bottom-content h2 {
  position: absolute;
  top: -10px;
  right: 30px;
  font-size: 8rem;
  color: rgba(255, 255, 255, 0.1);
}

.bottom_conatiner .bottom-card .bottom-box .bottom-content h3 {
  font-size: 1.8rem;
  color: #fff;
  z-index: 1;
  transition: 0.5s;
  margin-bottom: 15px;
}

.bottom-box .logo {
  position: absolute;
  bottom: 0;
  transition: all 300ms ease-in-out;
}

.bottom_conatiner .bottom-card .bottom-box .bottom-content p {
  font-size: 1rem;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.9);
  z-index: 1;
  transition: 0.5s;
}

.bottom-content .game-card {
  width: 65%;
  background-color: #c546d3;
  position: relative;
  top: 21.5vw;
  font-size: 1.2vw;
  padding: 6px 23px 6px 25px;
  margin-bottom: 0.4vw;
  text-decoration: none;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: 0.5s;
  z-index: -1;
}

.bottom-game-title {
  position: relative;
  top: 23vw;
}

.bottom_conatiner .bottom-card .bottom-box .bottom-content a:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.6);
  background: #fff;
  color: red;
}

.bottom-content .svg-inline--fa {
  top: 0;
  height: 0.8em;
  left: 5px;
}

#swipe-down {
  color: #fff;
  top: -10vw;
  left: 48%;
  width: 30px;
  height: auto;
  z-index: 999;
  border-radius: 50vw;
  border: 5px #fff solid;
  padding: 12px;
  cursor: pointer;
  animation-name: upanddown;
  animation-duration: 1s;
  position: relative;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
}

@keyframes upanddown {
  0% {
    transform: translateY(0.4rem);
  }

  50% {
    transform: translateY(-0.5rem);
  }

  100% {
    transform: translateY(0.4rem);
  }
}

/* Content */
.content-container {
  margin: auto;
  color: #fff;
  width: 100%;
}

.content-container h1,
.content-container h2,
.content-container h3,
.content-container a {
  color: #79c000;
}

.gamesTitle {
  color: #fff;
  font-weight: 500;
  text-align: center;
}

.content-container .row .col {
  border-right: 2px #79c000 solid;
}

.content-container .row .col:nth-last-of-type(1) {
  border-right: unset;
}

/* Table */
.table {
  width: 100%;
  margin: auto;
  margin-bottom: 3vw;
}

.table > tbody {
  vertical-align: middle;
}

/* Table End  */

/* Content Accordion */
#acc-section {
  width: 70%;
  margin: auto;
}

#acc-section h2 {
  font-size: 1.8rem;
}

.accordion-body {
  color: #fff;
}

.accordion-body ul {
  padding-left: 0.9rem;
  margin-top: -3vw;
}

.content-accordion .accordion-button:not(.collapsed),
.content-accordion .accordion-button {
  color: #79c000;
  background-color: unset;
}

.accordion-button:not(.collapsed),
.accordion-button {
  background-color: unset;
}

.accordion-header,
.accordion-item {
  border: unset;
  background-color: unset;
}

.accordion-button:focus {
  border-color: unset;
  box-shadow: unset;
}

.accordion-button:after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

.hamb-accordion {
  color: #fff;
}

.hamb-accordion .accordion-item {
  border: unset;
}

.hamb-accordion .accordion-button:not(.collapsed),
.hamb-accordion .accordion-button {
  /* color: #fff; */
  font-size: 5vw;
  border-bottom: #fff 1px solid;
  border: unset;
  box-shadow: none;
}

.hamb-accordion .accordion-body {
  padding: 0;
}

.hamb-accordion .accordion-body li {
  padding-top: 1.1vw;
}

.hamb-accordion .accordion-body li a {
  width: 100%;
  display: block;
}

.bm-item-list a {
  align-items: center;
  color: #fff;
  display: flex !important;
  font-size: 1.1rem;
  justify-content: space-between;
  padding: 3vw 1vw 1vw;
  text-decoration: none;
  width: 100%;
}

.socials > div {
  background-color: white;
  padding: 7.5px;
  border-radius: 50%;
}

.footer-title {
  color: #79c000;
  border-left: 4px solid #79c000;
  padding: 0 10px;
}

.footer-item {
  padding: 0.15rem 0;
}

.footer-item:hover {
  color: #79c000;
  transition: all 50ms ease-in-out;
}

#legals {
  margin: auto;
  display: flex;
  width: 60%;
  font-size: 0.7vw;
  line-height: 25px;
  text-align: left;
  color: #a1a4a6;
}

#legals img {
  position: relative;
  width: 3vw;
  margin-right: 1vw;
}

.juegas-banners img {
  max-height: 4rem;
}

.tycTopBanner {
  position: relative;
  top: -6vw;
  left: 20.5vw;
  width: 17vw;
  font-size: 0.9vw;
  padding: 0.7vw 0 0.7vw;
  z-index: 1;
  color: white;
  padding-top: -15.5rem;
  text-align: center;
  text-decoration: none;
}

.tycTopBanner:hover {
  color: white;
}

.carousel-item {
  text-decoration: none;
}

/* End Footer */

.color-gr {
  color: #79c000;
}

.animate {
  margin: 10px 0;
  font-size: 2.1rem;
}

.bono {
  border: solid 2px #79c000;
}
.modal:not(.login-modal) .modal-content {
  /* top: 5vw; */
}
/* Mobile + Tablet*/

@media only screen and (max-width: 1400px) {
  #des-reg {
    top: 31vw;
  }
}

@media only screen and (max-width: 767px) {
  .modal-content {
    transform: scale(0.85);
  }
  .disclamer {
    font-size: 2.5vw;
  }
  .modal-footer {
    margin-top: 16vw;
  }

  /* .modal:not(.login-modal) .modal-content {
    top: 26vw;
  } */

  button.btn.btn-primary {
    width: 100%;
  }

  .hasta {
    font-size: 1rem !important;
  }

  .animate {
    font-size: 4vw;
  }

  .security {
    flex-wrap: wrap;
    padding: 20px 20px 0;
  }

  .security img {
    max-width: 100%;
    max-height: 65px;
    height: auto;
  }

  .mob-tyc {
    text-align: center;
  }

  .mob-tyc a,
  .mob-tyc span {
    color: #fff;
    position: relative;
    top: 0.7vw;
    bottom: 0;
    font-size: 3.5vw;
    z-index: 2;
  }

  .tycTopBanner {
    top: -6vw;
    left: 35.5vw;
    font-size: 3vw;
  }

  /* .mobMenuSVGwrapper svg {
    height: 1.6em !important;
    margin-right: 0.5rem;
    min-width: 36px;
  } */
  .bm-item-list a {
    align-items: center;
    color: #fff;
    display: flex !important;
    font-size: 1.1rem;
    justify-content: space-between;
    padding: 3vw 1vw 1vw;
    text-decoration: none;
    width: 100%;
  }

  .form-control {
    width: 100%;
  }

  /* Buttons Mobile */
  .acceder-button {
    font-size: 3.5vw;
  }

  .registrate-button {
    font-size: 3.5vw;
    margin: 0;
    /* font-weight: bold; */
  }

  /* End Buttons Mobile */
  #outer-container > a {
    /* display: block;
    position: relative; */
    /* width: 33vw; */
    /* height: 9vw; */
    /* left: 8vw;
    margin-right: 13vw; */
  }

  .main-logo img {
    width: 100%;
    position: relative;
    left: 12vw;
    top: -0.75vw;
    /* max-height: 30px; */
    max-width: 30vw;
  }

  .header_nav {
    width: 100%;
  }

  #header_buttons {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
    padding: 0 3vw;
  }

  #header_buttons .btn {
    width: 21vw;
    display: flex;
    justify-content: center;
  }

  .bm-item-list a {
    text-decoration: none;
    font-size: 1.1rem;
    padding: 1vw;
    padding-top: 3vw;
  }

  /* Hamburger */

  nav ul,
  nav li {
    list-style: none;
  }

  .bm-burger-button {
    position: absolute;
    width: 28px;
    height: 20px;
    left: 3vw;
    top: 1.5rem;
  }

  .bm-menu-wrap,
  .bm-overlay {
    top: 0;
  }

  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    opacity: 1 !important;
    background: #79c000;
    border-radius: 60px;
  }

  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: #79c000;
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 12px;
    width: 12px;
  }

  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }

  /*
    Sidebar wrapper styles
    Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
    */
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
  }

  /* General sidebar styles */
  .bm-menu {
    background: rgba(50, 63, 72);
    padding: 0 0.5em 0 1.5em;
    font-size: 1.15em;
  }

  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: rgba(50, 63, 72);
    overflow-y: auto;
  }

  #elastic .accordion-body {
    height: 65vh;
  }

  #elastic,
  #outer-container .bm-overlay {
    height: 100% !important;
    top: 4rem !important;
  }

  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    padding-top: 0.8em;
    width: 90%;
    overflow-y: auto;
  }

  .bm-menu .fa-angle-right {
    float: right;
  }

  /* Individual item */
  .bm-item {
    display: inline-block;
  }

  .bm-item:focus-visible,
  .bm-item:focus {
    outline: unset;
  }

  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.7) !important;
  }

  .bm-burger-button > span > span:nth-child(1) {
    width: 90%;
    transition: transform 100ms;
  }

  .bm-burger-button > span > span:nth-child(2) {
    width: 90%;
    transition: transform 100ms;
  }

  .bm-burger-button > span > span:nth-child(3) {
    width: 70%;
    transition: transform 100ms;
  }

  .open > span > span:nth-child(1) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    transition: transform 700ms;
  }

  .open > span > span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    transition: transform 400ms;
  }

  .open > span > span:nth-child(1) {
    top: 2vw !important;
    width: 80%;
    left: 5%;
  }

  .open > span > span:nth-child(2) {
    width: 0%;
  }

  .open > span > span:nth-child(3) {
    top: 2vw !important;
    width: 80%;
    left: 5%;
  }

  /* End Hamburger */
  .navbar-expand .navbar-nav .nav-link {
    font-size: 1.2vw;
    font-weight: bold;
    text-align: center;
  }

  #carousel-section,
  .inicio {
    padding-top: 4rem;
    margin-bottom: 12vw;
  }

  /* Games */
  #mobile_games {
    margin-top: -10vw;
    margin-bottom: 10vw;
  }

  #mobile_games .game-title {
    text-align: center;
    font-size: 1.25rem;
    color: #79c000;
    margin-bottom: 5px;
  }

  .game-carousel {
    margin-bottom: 10vw;
  }

  .swiper-pagination {
    bottom: 0 !important;
  }

  .swiper-pagination-bullet {
    width: var(
      --swiper-pagination-bullet-width,
      var(--swiper-pagination-bullet-size, 10px)
    );
    height: var(
      --swiper-pagination-bullet-height,
      var(--swiper-pagination-bullet-size, 10px)
    );
  }

  /* End Games  */
  /* Content */
  .content-container .col-sm-4 {
    margin: 0;
  }

  /* End Content */
  /*  Sticky Footer */
  .sticky-footer-contatiner {
    text-align: center;
    position: fixed;
    bottom: 0;
    background-color: #252a30;
    height: 17vw;
    width: 100%;
    z-index: 5;
    padding: 2vw;
  }

  .sticky-item {
    display: inline-block;
    font-size: 4.4vw;
    padding: 2vw 0.5vw 2vw 0.5vw;
    box-shadow: none;
    text-decoration: none;
    color: #fff;
    font-weight: 600;
    width: 31vw;
    background: #79c000;
    border-radius: 40px;
    text-align: center;
    margin-left: 1vw;
    -webkit-box-shadow: 0px 0px 12px 1px rgb(0 0 0 / 75%);
    -moz-box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 12px 1px rgb(0 0 0 / 75%);
    border: none;
  }

  .faq {
    width: 95% !important;
  }

  /* End Sticky Footer */
  /* Footer */

  #legals {
    width: 100%;
    padding: 0 10px;
    font-size: 2.8vw;
    line-height: 1.2;
    margin: 0.25rem 0 4.4rem;
  }

  #legals img {
    width: auto;
    margin: 0 2vw;
  }

  /* End Footer */
  #acc-section {
    width: 100%;
  }
}

/* Laptops, Desktops */
@media (min-width: 1025px) and (max-width: 1280px) {
  .navbar-dark .navbar-nav a {
    padding: 0.5vw;
    font-size: 1vw;
  }
}

/* Desktops */
@media (min-width: 1381px) {
  .navbar-nav {
    margin-left: 150px !important;
  }
}

@media only screen and (max-width: 1537px) {
  .navbar-nav {
  }

  .text-uppercase {
    font-size: 13px;
  }
}
