.banner_info:hover,
.banner_info span,
.banner_info span:visited,
.banner_info p,
.banner_info p:visited {
  color: #fff;
  text-decoration: none !important;
}

.banner_info {
  text-decoration: none !important;
  /* background-color: rgba(51, 63, 72, 0.8); */
  position: absolute;
  width: 23vw;
  /* height: 18.5vw; */
  top: 19.25vw;
  left: 14vw;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  z-index: 5;
  border-radius: 8px;
}

.spinner-border {
  margin: auto;
  color: #79c000;
}

div:first-child > .banner_title {
  font-family: "Roboto Condensed";
  font-size: 1.15rem;
  font-weight: 400;
  line-height: 0.5;
  margin-top: 0.75vw;
}

.show_counter {
  z-index: 2;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 4vw;
  padding: 0.5rem;
  border-radius: 0.25rem;
  text-decoration: none;
  color: #fff;
}

.show_counter .countdown {
  line-height: 3.5rem;
  padding: 0 0.15rem 0 0.15rem;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.count_styling {
  font-family: "Roboto Condensed";
  background-color: #c546d3;
  border-radius: 5px;
  font-size: 3.5vw;
  margin-right: 1px;
  margin-left: 1px;
  width: 2.2vw;
  height: 3.2vw;
  line-height: 3.2vw;
  text-align: center;
  font-family: "Roboto Condensed";
}

.semicol {
  font-size: 1.75vw;
  margin-top: -0.5vw;
}

.show_counter .countdown > p {
  margin: 0;
}

.show_counter .countdown > span {
  text-transform: uppercase;
  font-size: 0.75rem;
  line-height: 1rem;
}

/* End Counter */

@media only screen and (max-width: 767px) {
  .banner_info {
    width: 75vw;
    /* height: 56vw; */
    top: 58vw;
    left: 12vw;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
  }

  .count_styling {
    font-size: 10vw;
    width: 6vw;
    height: 2.3rem;
    line-height: 2.4rem;
    margin-top: 3vw;
  }

  .time_unit {
    font-size: 0.55rem !important;
  }

  .banner_title {
    /* font-family: Codere; */
    font-size: 11.5vw;
    font-weight: 400;
    line-height: 10vw;
  }

  .banner_title:nth-child(1) {
    margin-top: 4vw;
  }

  .semicol {
    font-size: 1.5rem;
  }

  div:first-child > .banner_title {
    font-family: "Roboto Condensed";
    font-size: 6vw;
    font-weight: 400;
    line-height: 2.25vw;
    margin-top: 3vw;
  }

  div:nth-child(2) > .banner_title {
    font-family: "Roboto Condensed";
    font-size: 8.5vw;
    font-weight: 400;
    line-height: 4vw;
    color: #79c000;
    margin-top: 4vw;
  }

  div:nth-child(3) > .banner_title {
    font-family: "Roboto Condensed";

    font-size: 7.5vw;
    font-weight: 400;
    line-height: 8.85vw;
    margin-top: 3vw;
  }

  div:nth-child(4) {
    opacity: 1;
  }

  div:nth-child(4) > .banner_title {
    font-size: 3vw;
    font-weight: 400;
    line-height: 1.85vw;
    margin-top: 2vw;
    margin-bottom: -2vw;
  }
}

@media (min-width: 1025px) and (max-width: 1280px) {
  .banner_info {
    top: 21.7vw;
  }
  div:first-child>.banner_title {
    font-size: 0.9rem;
}

.count_styling {
    height: 2.5vw;
    font-size: 2.3vw;
    line-height: 2.7vw;
}

}
