:root {
  --logoGrey: #323f47;
  --green-codere: #79c000;
}

.error {
  color: red;
  text-align: left;
  margin-left: 5pt;
  font-size: 0.75rem;
  line-height: 1.15;
}

div:has(> [title="el desafío de recaptcha caduca dentro de dos minutos"]) {
  transform: scale(0.77);
}

.grecaptcha-badge {
  visibility: hidden;
}

.arrow {
  transform: rotate(180deg);
  margin-top: 0.5vw;
  max-height: 35px;
  filter: brightness(0) saturate(100%) invert(22%) sepia(11%) saturate(931%)
    hue-rotate(159deg) brightness(93%) contrast(89%);
}

.form-check-label {
  color: var(--dark-grey) !important;
}

.form-control {
  /* background-color: rgb(201, 201, 201, 0.09); */
  color: var(--light-grey);
  margin-top: 10pt;
}

.form-floating > .form-control:focus {
  box-shadow: unset;
  /* border: 2px solid #1a73e8; */
  border: 2px solid var(--green-codere);
  /* border-width: 2px; */
  outline: none;
}

.form-floating > .form-control {
  padding: 20px;
}

.form-floating > .form-control:focus:not(:placeholder-shown) ~ label {
  font-weight: 700;
  top: 0.115rem;
  left: 10px;
  color: var(--green-codere);
  /* color: #1a73e8; */
  font-size: 0.75rem;
  background-color: white;
  /* height: 10px; */
  padding-left: 5px;
  padding-right: 5px;
}

.form-floating > .form-control:not(:placeholder-shown) {
  padding: 0 0 0 1rem;
}
.form-floating > .form-control:placeholder-shown {
  padding: 0 0 0 0.75rem;
}

input:not([value=""]) ~ label,
.form-floating > .form-control:-webkit-autofill ~ label {
  top: -0.65rem !important;
  left: 20px;
  width: auto;
  height: 25px;
  opacity: 1 !important;
  transform: none !important;
  color: var(--green-codere);
  padding: 0;
  font-size: 0.75rem;
  font-weight: 700;
  background-color: white;
}

input:-internal-autofill-selected {
  background-color: white !important;
}

.form-floating > .form-control ~ label {
  transform: none;
}

.form-floating > .form-control:focus ~ label {
  opacity: 1;
  height: 20px;
  color: var(--green-codere);
  /* transform: none !important; */
  font-weight: 700;
  width: auto;
  padding: 0 0.25rem 0 0.25rem;
  background-color: white;
  transform: scale(0.85) translateY(-0.75rem) translateX(15px);
}

.Auth-form-container {
  transform: scale(1) translateY(0);
  transition: transform 300ms;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.Auth-form {
  width: auto;
  padding-bottom: 20px;
  border-radius: 18px;
  background-color: white;
}

.Auth-form-content {
  padding-left: 12%;
  padding-right: 12%;
}
.olvido {
  color: var(--green);
  font-weight: 700;
  padding: 0.35rem 0;
  text-align: center;
  font-size: 0.75rem;
}

.form-check {
  justify-items: center;
  padding-left: 1.7em;
}

.form-check-input[type="checkbox"] {
  /* width: 1.1rem;
    height: 1.1rem; */
  margin-top: 0.35em;
  border: 1px solid var(--dark-grey);
  box-shadow: none;
  font-size: 1rem;

  /* margin-left: -4.2vw; */
}

.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
  /* -webkit-text-fill-color: green; */
}

.form-floating > .form-control:-webkit-autofill ~ label {
  -webkit-text-fill-color: var(--green-codere);
}

.form-check-input[type="checkbox"]:checked {
  border: 1px solid var(--green-codere);
  background-color: var(--green-codere);
}

.login-modal .modal-dialog {
  top: 100px;
  max-width: 420px;
}

.modal-header.Auth-form-title {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 600;
  border: unset;
  display: flex;
}

.form-floating label {
  color: var(--dark-grey);
  /* top: -1rem; */
  /* color: ; */
}

.modal-terms {
  font-size: 0.75rem;
  padding: 0 10px;
}

.modal-terms a {
  color: var(--green-codere);
  text-decoration: unset;
}

label {
  font-size: 14px;
  font-weight: 600;
  color: rgb(34, 34, 34);
}

.buttons {
  width: 80%;
  margin: auto;
}

.iniciar,
.registro,
.registro_v2 {
  font-size: 1.25rem;
  letter-spacing: 0.5px;
  padding: 0.75rem 0;
  border-radius: 4px;
  text-transform: uppercase;
  font-weight: 700;
}

.btn:disabled {
  background-color: var(--green-codere);
  opacity: 0.65;
}

:not(.btn-check) + .btn:active {
  background-color: var(--green-codere);
}

.iniciar {
  box-shadow: rgba(99, 99, 99, 0.25) 0px 2px 8px 0px;
  background-color: var(--green-codere);
  border: var(--grey);
}

.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active,
.iniciar:hover {
  background-color: var(--green-codere);
  opacity: 0.65;
  color: #fff;
  border: unset;
}

.err_lbl {
  color: red;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.pi-eye,
.pi-eye-slash {
  position: relative;
  top: -2.25rem;
  right: 1rem;
  float: right;
  cursor: pointer;
  font-size: 1.25rem;
  color: var(--green-codere) !important;
}

@media screen and (max-width: 480px) {
  .arrow {
    max-height: 23px;
    margin-top: 3vw;
  }

  .registro_v2 {
    /* width: 68vw; */
    opacity: 1;
  }
  .btn-check:checked + .btn,
  .btn.active,
  .btn.show,
  .btn:first-child:active,
  :not(.btn-check) + .btn:active,
  .iniciar:hover {
    /* background-color: var(--green-codere); */
    opacity: 0.65;
    color: #fff;
    border: unset;
  }

  .iniciar,
  .registro,
  .registro_v2 {
    font-size: 4.5vw;
    padding: 0.75rem 8vw;
  }

  .olvido {
    font-size: 4vw;
  }

  .Auth-form-title {
    font-size: 5.5vw;
    /* padding-bottom: 19vw; */
  }
}

/* 
    ## Device = Desktops
  */

@media (min-width: 1281px) {
  /* CSS */
}

/* 
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
  */

@media (min-width: 1025px) and (max-width: 1280px) {
}

/* 
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

@media (min-width: 320px) and (max-width: 480px) {
}
